import api from './index'
import { axios } from '@/utils/request'

// 获取新闻列表
export function newsList(parameter) {
    return axios({
        url: api.NewsList,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取新闻详情
export function newsInfo(parameter) {
    return axios({
        url: api.NewsInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取新闻新增页面附加数据
export function newsAdditional(parameter) {
    return axios({
        url: api.NewsAdditional,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 创建新闻
export function newsCreate(parameter) {
    return axios({
        url: api.NewsCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改新闻
export function newsSave(parameter) {
    return axios({
        url: api.NewsSave,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除
export function newsDelete(parameter) {
    return axios({
        url: api.NewsDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
