<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="关键字">
              <a-input v-decorator="[
                'keywords',
                {
                  initialValue: queryParam.keywords
                }
              ]" placeholder="标题/关键字/描述" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="分类">
              <a-select placeholder="请选择" v-decorator="[
                'classify',
                {
                  initialValue: queryParam.classify
                }
              ]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(option, index) in news_classify_type" :key="index" :value="option">{{ option }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-decorator="[
                'status',
                {
                  initialValue: queryParam.status
                }
              ]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="1">发布中</a-select-option>
                <a-select-option value="0">编辑中</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="loading.search" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
              <a-button style="float: right" @click="goAddNews">添加新闻</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :dataSource="tableData" :loading="loading.data" rowKey="id">
      <span slot="status" slot-scope="text">
        {{ text | statusFilter }}
      </span>

      <span slot="type" slot-scope="text">
        {{ text | typeFilter }}
      </span>

      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="goEditNews(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm v-if="tableData.length" title="确定删除该新闻?" @confirm="() => onDelete(record.id)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

  </a-card>
</template>

<script>
import { newsList, newsDelete } from '@/api/news'
import merge from 'webpack-merge'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '分类',
    dataIndex: 'classify'
  },
  {
    title: '标题',
    dataIndex: 'title'
  },
  {
    title: '关键字',
    dataIndex: 'keywords'
  },
  {
    title: '描述',
    dataIndex: 'description'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  name: 'NewsList',
  data() {
    return {
      description: '公告列表页面',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      queryParam: {

      },
      loading: {
        search: false,
        data: false
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      columns,
      tableData: [],
      news_classify_type: [],
      ModalText: '确定删除？'
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: '发布中',
        0: '编辑中'
      }
      return statusMap[status]
    },
    typeFilter(status) {
      const typeMap = {
        'all': '所有',
        'user': '用户',
        'teacher': '教员'
      }
      return typeMap[status]
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      const parameter = {}
      parameter.status = this.form.getFieldValue('status')
      parameter.keywords = this.form.getFieldValue('keywords')
      parameter.classify = this.form.getFieldValue('classify')
      this.loading.search = true
      this.loadData(parameter)
      this.loading.search = false
      this.$router.push({
        query: merge(this.$route.query, parameter)
      })
    },
    loadData(parameter) {
      this.loading.data = true
      new Promise((resolve, reject) => {
        newsList(parameter).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        this.tableData = data.list
        this.news_classify_type = data.news_classify_type
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.loading.data = false
      })
    },
    goAddNews() {
      this.$router.push({ 'name': 'InformationNewsEdit' })
    },
    goEditNews(record) {
      this.$router.push({ 'name': 'InformationNewsEdit', query: { id: record.id } })
    },
    onDelete(id) {
      new Promise((resolve, reject) => {
        newsDelete({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        // 删除成功
        this.$notification.success({
          message: res.status,
          description: res.message
        })
        this.loadData()
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
      })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({ status: '', keywords: '', classify: '' })
      this.$router.push({
        query: merge({}, {})
      })
      this.loadData()
    }
  }
}
</script>
